<template>
    <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
            <v-card v-if="history.length > 0" flat class="pb-3">
                <v-subheader>{{ $t('paymentHistory.total-of') }} {{ count }} {{ $t('paymentHistory.transaction-records') }}</v-subheader>
                <v-list subheader>
                    <template v-for="payment in history">
                        <v-list-tile :key="payment.id" avatar @click="openDetail(payment.id)">
                            <v-list-tile-avatar>
                                <v-icon large color="indigo">{{ getIcon(payment.action) }}</v-icon>
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ payment.box_name }}</v-list-tile-title>
                                <v-list-tile-sub-title> {{ formatTimestamp(payment.create_timestamp) }} </v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                                <span class="green--text title">{{ payment.amount_sum }} {{ $t('paymentHistory.NTD') }}</span>
                                <v-list-tile-action-text>{{ payment.type_name }}</v-list-tile-action-text>
                            </v-list-tile-action>
                        </v-list-tile>
                        <v-divider :key="'divider'+payment.id"></v-divider>
                    </template>
                </v-list>
                <div class="text-xs-center">
                    <v-pagination v-show="total_page > 0" v-model="page" :length="total_page">
                    </v-pagination>
                </div>
            </v-card>
            <v-card v-else flat class="pt-5 pb-5">
                <center class="title">{{ $t('paymentHistory.no-history') }}</center>
            </v-card>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { SideBtnType } from '@/store'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { formatDatetime } from '@/utils/utils'
import i18n from '@/i18n'

export default {
    name: 'payment-history',
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    data() {
        return {
            count: 0,
            page: 1,
            total_page: 0,
            history: []
        }
    },
    watch: {
        page() {
            this.getPaymentHistory()
        }
    },
    methods: {
        openDetail(paymentId) {
            this.$router.push({ name: 'paymentDetail', params: { id: paymentId } })
        },
        getIcon(action) {
            switch(action) {
                case 'TEST':
                    return ''
                case 'PUTIN':
                    return 'move_to_inbox'
                case 'TAKEOUT':
                    return 'unarchive'
                default:
                    return 'payment'
            }
        },
        formatTimestamp(ts) {
            let d = new Date(ts)
            return formatDatetime(d, false)
        },
        getPaymentHistory() {
            this.showDialog(i18n.t('paymentHistory.get-history'), i18n.t('paymentHistory.fail-get-records'))
            var vm = this
            axios.get(HostUrl + '/api/payment/history?p=' + vm.page, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        vm.count = response.data.count
                        vm.total_page = response.data.total_page
                        vm.history = response.data.payments
                        for(let i = 0; i < vm.history.length; i++) {
                            if(vm.history[i].type_name === '信用卡') {
                                vm.history[i].type_name = i18n.t('paymentHistory.credit-card')
                            } else if (vm.history[i].type_name === '電子票證') {
                                vm.history[i].type_name = i18n.t('paymentHistory.electronic-money')
                            }
                        }
                    } else {
                        store.commit('setSnackBar', {
                            message: response.data.message,
                            color: 'error'
                        })
                    }
                    vm.closeDialog()
                })
                .catch(function () {
                    store.commit('setSnackBar', {
                        message: i18n.t('paymentHistory.fail-get-records-later'),
                        color: 'error'
                    })
                })
        }
    },
    activated() {
        store.commit('setTitle', 'paymentHistory.nav-title')
        store.commit('setSideBtnType', SideBtnType.Back)
        this.getPaymentHistory()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>